import {createGlobalStyle} from "styled-components";
import {device} from "./Screen";

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }
  button {
    font-family: 'Poppins', sans-serif;
  }
`;
export const Shadows = {
  high: '0px 12px 20px rgba(106, 115, 129, 0.22)',
  med: '0px 6px 12px rgba(106, 115, 129, 0.16)',
  low: '0px 2px 8px rgba(106, 115, 129, 0.12)',
  none: 'none',
}
export const BorderRadius = {
  small: '2px',
  medium: '4px',
  large: '8px',
  xLarge: '16px',
}
export const Opacity = {
  light: 0.25,
  medium: 0.5,
  dark: 0.75,
}

export interface GlobalProps {
  hideXs?: boolean;
  hideSm?: boolean;
  hideMd?: boolean;
  hideLg?: boolean;
  hideXl?: boolean;
  hideXxl?: boolean;
  showOnlyXs?: boolean;
  showOnlySm?: boolean;
  showOnlyMd?: boolean;
  showOnlyLg?: boolean;
  showOnlyXl?: boolean;
  showOnlyXxl?: boolean;
  display?: 'block' | 'inline-block' | 'inline' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid' | 'table' | 'inline-table' | 'contents' | 'none';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'start' | 'end' | 'left' | 'right';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'first baseline' | 'last baseline' | 'start' | 'end' | 'self-start' | 'self-end';
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'start' | 'end' | 'baseline' | 'first baseline' | 'last baseline';
}

const hideAllWithMedia = `
  @media ${device.xxl} { display: none; }
  @media ${device.xl} { display: none; }
  @media ${device.lg} { display: none; }
  @media ${device.md} { display: none; }
  @media ${device.sm} { display: none; }
  @media ${device.xs} { display: none; }
`;

export const useGlobalProps = (Props: GlobalProps) => {
  return `
    ${Props.hideXxl ? `@media ${device.xxl} { display: none; }` : ''}
    ${Props.hideXl ? `@media ${device.xl} { display: none; }` : ''}
    ${Props.hideLg ? `@media ${device.lg} { display: none; }` : ''}
    ${Props.hideMd ? `@media ${device.md} { display: none; }` : ''}
    ${Props.hideSm ? `@media ${device.sm} { display: none; }` : ''}
    ${Props.hideXs ? `@media ${device.xs} { display: none; }` : ''}
    ${Props.showOnlyXl ? `${hideAllWithMedia} @media ${device.xl} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.showOnlyXxl ? `${hideAllWithMedia} @media ${device.xxl} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.showOnlyLg ? `${hideAllWithMedia} @media ${device.lg} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.showOnlyMd ? `${hideAllWithMedia} @media ${device.md} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.showOnlySm ? `${hideAllWithMedia} @media ${device.sm} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.showOnlyXs ? `${hideAllWithMedia} @media ${device.xs} { display: ${Props.display ? Props.display : 'flex'}; }` : ''}
    ${Props.display ? `display: ${Props.display};` : ''}
    ${Props.justifyContent ? `justify-content: ${Props.justifyContent};` : ''}
    ${Props.alignItems ? `align-items: ${Props.alignItems};` : ''}
    ${Props.flexDirection ? `flex-direction: ${Props.flexDirection};` : ''}
    ${Props.alignContent ? `align-content: ${Props.alignContent};` : ''}
  `
};