import React, {useEffect, useState} from 'react';
import HeaderTop from "../../common/components/HeaderTop/HeaderTop";
import {data} from "../Home/Home";
import Menu from "../../common/components/Menu/Menu";
import Footer from "../../common/components/Footer/Footer";
import Team from "../../common/components/Team/Team";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../common/services/firebase";
import {getData, openLink} from "../../common/services/data";
import WhatsappIcon from "../../common/designSystem/Icons/Whatsapp";

const Workers = () => {
  const [ data, setData ] = useState({
    team: [],
    headerText: '',
    coursesTitle: '',
    coursesText: '',
    teamTitle: '',
    infrastructureTitle: '',
  });

  useEffect(() => {
    getData(setData);
  },[]);

  return (
    <div style={{overflow: "auto", overflowX: 'hidden'}}>
      <HeaderTop links={data?.links} phones={data?.phones} selected={3}/>
      <Menu simple={true} selected={3}/>
      <Team team={data?.team} title={data?.teamTitle} isWorkers/>
      <Footer description={data?.description} address={data} email={data?.email} selected={3}/>
      <HeaderTop links={data?.links} phones={data?.phones} isFooter></HeaderTop>
      <div onClick={() => openLink()} style={{cursor: "pointer", position: 'fixed', width: '40px', height: '40px', bottom: '22px', right: '22px', zIndex: 9999}}>
        <WhatsappIcon/>
      </div>
    </div>
  );
};

export default Workers;