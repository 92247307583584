import React, {useEffect, useState} from 'react';
import HeaderTop from "../../common/components/HeaderTop/HeaderTop";
import Menu from "../../common/components/Menu/Menu";
import Infrastructure from "../../common/components/Infrastructure/Infrastructure";
import {data} from "../Home/Home";
import Footer from "../../common/components/Footer/Footer";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../common/services/firebase";
import {getData, openLink} from "../../common/services/data";
import WhatsappIcon from "../../common/designSystem/Icons/Whatsapp";

const Sobre = () => {
  const [ data, setData ] = useState({
    links: [],
    phones: [],
    infraImages: [],
    infrastructureTitle: '',
    contactDescription: '',
    address: '',
    email: '',
  });

  useEffect(() => {
    getData(setData);
  },[]);

  return (
    <div style={{overflow: "auto", overflowX: 'hidden'}}>
      <HeaderTop links={data?.links} phones={data?.phones} selected={2}/>
      <Menu simple={true} selected={2}/>
      <Infrastructure imgs={data?.infraImages} title={data?.infrastructureTitle}/>
      <Footer description={data?.description} address={data} email={data?.email} selected={2}/>
      <HeaderTop links={data?.links} phones={data?.phones} isFooter></HeaderTop>
      <div onClick={() => openLink()} style={{cursor: "pointer", position: 'fixed', width: '40px', height: '40px', bottom: '22px', right: '22px', zIndex: 9999}}>
        <WhatsappIcon/>
      </div>
    </div>
  );
};

export default Sobre;