// @ts-ignore
import React, {useEffect, useState} from 'react';
import Dialog from "../../designSystem/Dialog/Dialog.tsx";
import {useNavigate} from "react-router-dom";
import Input from "../../designSystem/Input/Input";
import {Grid, GridItem} from "../../designSystem/Grid/Grid";
import Typography from "../../designSystem/Typography/Typography";
import styled from "styled-components";
import Menu from "../Menu/Menu";

const Container = styled.div`
  display: flex;
  height: calc(100% - 40px);
`;

const ContainerMenu = styled.div`
  display: flex;
  width: 240px;
`;

const ContainerChildren = styled.div`
  display: flex;
  width: calc(100% - 240px);
`;

const RestrictArea = ({children}) => {
  const [ blocked, setBlocked ] = useState(true);
  const [ login, setLogin ] = useState({userName: '', password: ''});
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(!token || token !== '@**!@#*JD*ASD(@*EHDAS(Q@**@#*@*#') {
      setBlocked(true);
    } else {
      setBlocked(false);
    }
  },[]);

  const checkLogin = () => {
    if (login?.userName === 'admin' && login?.password === 'tubemp23') {
      localStorage.setItem('token', '@**!@#*JD*ASD(@*EHDAS(Q@**@#*@*#');
      setBlocked(false);
    }
  }

  return (
    <Container>
      <Dialog open={blocked} setOpen={setBlocked} title={'Login'} buttonLabelCancel={'Cancelar'} isObligatory
              buttonLabelConfirm={'Logar'} onConfirm={() => checkLogin()} onCancel={() => navigate('/')}>
        <Grid noMargin rowGapXs={16}>
          <GridItem xs={6} justifyContent={'center'}>
            <Typography variant={'h3'} center>Acesso Restrito</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Input label={'Login'} placeholder={'Digite seu login'} onChange={event => setLogin(val => {return {...val, userName: event?.target?.value}})} />
          </GridItem>
          <GridItem xs={6}>
            <Input label={'Senha'} placeholder={'Digite sua senha'} onChange={event => setLogin(val => {return {...val, password: event?.target?.value}})} type={'password'}/>
          </GridItem>
        </Grid>
      </Dialog>
      <ContainerMenu>
        <Menu/>
      </ContainerMenu>
      <ContainerChildren>
        {children}
      </ContainerChildren>
    </Container>
  );
};

export default RestrictArea;