import React, {useEffect, useState} from 'react';
import HeaderTop from "../../common/components/HeaderTop/HeaderTop";
import Banner from "../../common/components/Banner/Banner";
import HeaderBottom from "../../common/components/HeaderBottom/HeaderBottom";
import Courses from "../../common/components/Courses/Courses";
import Team from "../../common/components/Team/Team";
import Infrastructure from "../../common/components/Infrastructure/Infrastructure";
import Contact from "../../common/components/Contact/Contact";
import Footer from "../../common/components/Footer/Footer";
import {getData, openLink} from "../../common/services/data";
import WhatsappIcon from "../../common/designSystem/Icons/Whatsapp";
const Home = () => {
  const [ contact, setContact ] = useState({});
  const [ data, setData ] = useState({
    links: [],
    phones: [],
    team: [],
    infraImages: [],
    headerText: '',
    coursesTitle: '',
    coursesText: '',
    teamTitle: '',
    infrastructureTitle: '',
    description: '',
    email: '',
    address: '',
  });

  useEffect(() => {
    getData(setData);
  },[]);

  return (
    <>
      <div onClick={() => openLink()} style={{cursor: "pointer", position: 'fixed', width: '40px', height: '40px', bottom: '22px', right: '22px', zIndex: 9999}}>
        <WhatsappIcon/>
      </div>
      <section id="home">
        <HeaderTop links={data?.links} phones={data?.phones}></HeaderTop>
        <Banner/>
        <HeaderBottom text={data?.headerText}></HeaderBottom>
      </section>
      <section id="cursos">
        <Courses setContact={setContact} title={data?.coursesTitle} text={data?.coursesText} isHome/>
      </section>
      <section id="quem-somos">
        <Team team={data?.team} title={data?.teamTitle}/>
        <Infrastructure imgs={data?.infraImages} title={data?.infrastructureTitle}/>
      </section>
      <section style={{padding: '80px 40px 40px 40px'}} id="contato">
        <Contact setContact={setContact} contact={contact} description={data?.description} phones={data?.phones} email={data?.email} address={data}/>
      </section>
      <Footer description={data?.description} address={data}  email={data?.email}/>
      <HeaderTop links={data?.links} phones={data?.phones} isFooter></HeaderTop>
    </>
  );
};

export default Home;