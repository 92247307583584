import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Home from "./pages/Home/Home";
import Sobre from "./pages/Sobre/Sobre";
import Workers from "./pages/Workers/Workers";
import Courses from "./pages/Courses/Courses";
import HomeAdmin from "./pages/Admin/Home/Home";
import CoursesAdmin from "./pages/Admin/Courses/Courses";
import Information from "./pages/Admin/Information/Information";
import OurTeam from "./pages/Admin/OurTeam/OurTeam";
import {SnackbarProvider} from "notistack";

ReactDOM.render(
  <React.StrictMode>
      <SnackbarProvider maxSnack={3} />
      <BrowserRouter>
          <Routes>
            <Route path="/quem-somos" element={<Sobre />} />
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<HomeAdmin />} />
            <Route path="/admin/information" element={<Information />} />
            <Route path="/admin/courses" element={<CoursesAdmin />} />
            <Route path="/admin/our-team" element={<OurTeam />} />
            <Route path="/cursos/:courseArea/:courseName" element={<Courses />}/>
            <Route path="/cursos/:courseArea" element={<Courses />}/>
            <Route path="/cursos" element={<Courses />}/>
            <Route path="/profissionais" element={<Workers />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);