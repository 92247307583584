import React, {useEffect, useState} from 'react';
import HeaderTop from "../../common/components/HeaderTop/HeaderTop";
import Menu from "../../common/components/Menu/Menu";
import Footer from "../../common/components/Footer/Footer";
import Courses from "../../common/components/Courses/Courses";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../common/services/firebase";
import {Link, useParams} from 'react-router-dom';
import {textToURL, urlToText} from "../../common/utils/text";
import Button from "../../common/designSystem/Button/Button";
import Contact from "../../common/components/Contact/Contact";
import styled from "styled-components";
import {getData, openLink} from "../../common/services/data";
import WhatsappIcon from "../../common/designSystem/Icons/Whatsapp";

const ListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  flex-direction: row;
  justify-content: center;
`;
const CoursesPage = () => {
  const [ contact, setContact ] = useState({});
  const { courseArea ,courseName } = useParams();
  const [ courseAreaTemp, setCourseAreaTemp ] = useState(courseArea);
  const [ courseNameTemp, setCourseNameTemp ] = useState(courseName);
  const [ showContact, setShowContact ] = useState(false);
  const [ course, setCourse ] = useState({});
  const [ data, setData ] = useState({
    links: [],
    phones: [],
    courses: [],
    team: [],
    headerText: '',
    coursesTitle: '',
    coursesText: '',
    teamTitle: '',
    infrastructureTitle: '',
  });

  useEffect(() => {
    getData(setData);
  },[]);

  useEffect(() => {
    setCourseNameTemp(courseName);
    setCourseAreaTemp(courseArea);
    setShowContact(false);
  },[courseArea, courseName]);

  useEffect(() => {
    if (data?.courses?.courses?.length !== 0) {
      const find = data?.courses?.courses?.find(course =>
        textToURL(course?.name) === courseNameTemp && textToURL(course?.area?.name) === courseAreaTemp);
      setCourse(find);
    }
  }, [courseAreaTemp, courseNameTemp, data])

  return (
    <div style={{overflow: "auto", overflowX: 'hidden'}}>
      <HeaderTop links={data?.links} phones={data?.phones} selected={1}/>
      <Menu simple={true} selected={1}/>
      {!!course && <div style={{display: 'flex', padding: '10px 24px', alignItems: 'flex-start', alignContent: 'space-around', flexDirection: 'column'}}>
        <span style={{padding: '24px 0', fontWeight: 600, fontSize: '22px'}}>
          <Link to={`/cursos/${textToURL(course?.area?.name)}`}>{course?.area?.name}</Link> - {course?.name}</span>
        <div style={{display: 'flex', width: '100%', alignItems: 'center', flexDirection: 'column'}}>
          <img style={{maxWidth: '100%', maxHeight: '400px', borderRadius: '8px'}} src={course?.img}/>
          <span style={{padding: '24px 0 0 0', fontSize: '18px'}}>{course?.description}</span>
          <div style={{marginTop: '16px'}}>
            <Button title={'Garantir Vaga'} color={'success'} onClick={() => {
              setShowContact(val => !val);
              setContact(val => {
                return {...val, subject: 'Interesse no curso: ' + course?.name}
              })
            }} variant={'filled'}  />
          </div>
        </div>
      </div>}
      {showContact && <div style={{margin: '0 16px 0 16px'}}><Contact isCurse contact={contact} setContact={setContact} phones={data?.phones} email={data?.email} address={data} description={data?.description} /></div>}
      {(!courseNameTemp && !courseAreaTemp) && <div style={{display: 'flex', padding: '10px 24px 0 24px', alignItems: 'center', alignContent: 'space-around', flexDirection: 'column'}}>
        <span style={{padding: '24px 0', fontWeight: 500, fontSize: '20px'}}>Nossas Áreas</span>
        <ListArea>
          {data?.courses?.areas?.map(area => <Link style={{padding: '6px', margin: '8px', background: 'rgb(243 249 255)', border: '1px solid #091D30', borderRadius: '8px'}} to={`/cursos/${textToURL(area?.name)}`}>{area?.name}</Link>)}
        </ListArea>
      </div>}
      {(!courseNameTemp && !!courseAreaTemp) && <Courses setContact={setContact} title={data?.coursesTitle} text={data?.coursesText} areaNameFilter={urlToText(courseAreaTemp)}/>}
      {(!courseNameTemp && !!courseAreaTemp) && <div style={{display: 'flex', padding: '0px 24px 0 24px', marginTop: '-50px', alignItems: 'flex-start', alignContent: 'space-around', flexDirection: 'column'}}>
        <span style={{padding: '24px 0', fontWeight: 500, fontSize: '20px'}}>Outras Áreas</span>
        <ListArea>
          {data?.courses?.areas?.filter(area => textToURL(area.name) !== courseAreaTemp).map(area => <Link style={{padding: '6px', margin: '8px', background: 'rgb(243 249 255)', border: '1px solid #091D30', borderRadius: '8px'}} to={`/cursos/${textToURL(area?.name)}`}>{area?.name}</Link>)}
        </ListArea>
      </div>}
      <Courses setContact={setContact} title={data?.coursesTitle} text={data?.coursesText}/>
      <Footer description={data?.description} address={data} email={data?.email} selected={1}/>
      <HeaderTop links={data?.links} phones={data?.phones} isFooter></HeaderTop>
      <div onClick={() => openLink()} style={{cursor: "pointer", position: 'fixed', width: '40px', height: '40px', bottom: '22px', right: '22px', zIndex: 9999}}>
        <WhatsappIcon/>
      </div>
    </div>
  );
};

export default CoursesPage;