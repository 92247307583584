import React, {useEffect, useState} from 'react';
import RestrictArea from "../../../common/adminComponents/RestrictArea/RestrictArea";
import {deleteDocument, getDataDocs, updateDocument} from "../../../common/services/firestore";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import {database} from "../../../common/services/firebase";
import Button from "../../../common/designSystem/Button/Button";
import {Modal} from "@mui/material";
import {getHTMLfromText} from "../../../common/utils/text";

const Home = () => {
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});
  const [open, setOpen] = useState(false);
  const getContacts = async () => {
    const response = getDataDocs(await getDocs(query(collection(database, "contact"), orderBy("createAt", "desc"))));
    setContacts(response);
  }

  useEffect(() => {
    getContacts();
  }, []);

  const setReaded = async contact => {
    contact.read = !contact.read;
    await updateDocument('contact', contact);
    await getContacts();
  }

    const deleteContact = async contact => {
    setOpen(false);
    await deleteDocument('contact', contact);
    await getContacts();
  }

  return (
    <RestrictArea>
      {contacts.length > 0 ? <div style={{display: 'flex', flexDirection: 'column', overflowY: 'auto', width: '100%'}}>
        <div style={{alignItems: 'center', display: 'flex', padding: '16px', width: 'calc(100% - 32px)', background: 'rgb(110,110,110)', color: '#fff'}}>
          <div style={{width: '40px', marginRight: '8px'}}>Lido</div>
          <div style={{width: '20%'}}>Nome</div>
          <div style={{width: '20%'}}>E-mail</div>
          <div style={{width: '15%', textAlign: 'start'}}>Telefone</div>
          <div style={{width: 'calc(33% - 38px)'}}>Assunto</div>
          <div style={{width: '12%'}}></div>
        </div>
        {contacts.map((contactTemp, index) => <div style={{alignItems: 'center', display: 'flex', padding: '16px', width: 'calc(100% - 32px)', background: index % 2 === 0 ? 'transparent' : 'rgb(208 208 208)'}}>
            <div onClick={() => setReaded(contactTemp)} style={{cursor: 'pointer', width: '30px', height: '30px', marginRight: '18px', borderRadius: '15px', background: contactTemp?.read ? 'transparent' : '#5eb49f'}}></div>
            <div style={{width: '20%'}}>{contactTemp?.name}</div>
            <div style={{width: '20%'}}>{contactTemp?.email}</div>
            <div style={{width: '15%', textAlign: 'start'}}>{contactTemp?.phone}</div>
            <div style={{width: 'calc(33% - 48px)'}}>{contactTemp?.subject}</div>
            <div style={{width: '12%', display: 'flex', justifyContent: 'flex-end'}}>
              <Button title={'Abrir'} color={'success'} onClick={() => {
                setContact(contactTemp);
                setReaded(contactTemp);
                setOpen(true);
              }} variant={'filled'}/>
            </div>
          </div>
        )}
      </div> : <div style={{padding: '16px'}}>
        Nenhum contato até o momento.
      </div>}
      <Modal open={open} onClose={() => setOpen(false)}>
        <div style={{width: 'calc(100% - 40px)', height: 'calc(100% - 40px)', margin: '20px', background: '#fff'}}>
          <div style={{alignItems: 'center', display: 'flex', padding: '16px', width: 'calc(100% - 32px)', background: 'rgb(208 208 208)'}}>
            <div onClick={() => setReaded(contact)} style={{cursor: 'pointer', width: '30px', height: '30px', marginRight: '18px', borderRadius: '15px', background: contact?.read ? 'transparent' : '#5eb49f'}}></div>
            <div style={{width: '20%'}}>{contact?.name}</div>
            <div style={{width: '20%'}}>{contact?.email}</div>
            <div style={{width: '15%', textAlign: 'start'}}>{contact?.phone}</div>
            <div style={{width: 'calc(33% - 48px)'}}>{contact?.subject}</div>
            <div style={{width: '12%', display: 'flex', justifyContent: 'space-between'}}>
              <Button style={{margin:'0 8px 0 0'}} title={'Excluir'} color={'error'} colorVariant={'a300'} onClick={() => deleteContact(contact)} variant={'filled'}/>
              <Button title={'Fechar'} color={'warning'} colorVariant={'a300'} onClick={() => setOpen(false)} variant={'filled'}/>
            </div>
          </div>
          <div style={{margin:'16px'}}>
            {contact?.comment}
            {contact?.comment === undefined && <span>Usuário não deixou nenhum comentário.</span>}
          </div>
        </div>
      </Modal>
    </RestrictArea>
  );
};

export default Home;