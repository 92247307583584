import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import {getHTMLfromText} from "../../utils/text";
import {Grid, Modal} from "@mui/material";

const Title = styled.h2`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${COLORS.BLACK};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 52px 0 28px 0;
`;

const Container = styled.div`
  width: 100%;
`;

const Card = styled.div`
  width: 100%;
  background: black;
  border-radius: 16px;
`;

const Figure = styled.figure`
  position: relative;
  float: left;
  margin: 0;
  cursor: pointer;
  border-radius: 16px;
  
  figcaption {
    opacity: 0;
  }
  
  :hover {
    figcaption {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    figcaption {
      opacity: 1;
    }
  }
`;

const Figcaption = styled.figcaption`
  background-color: #ffffff95;
  color: ${COLORS.BLACK};
  width: calc(100% - 40px);
  height: 45px;
  display: block;
  position: absolute;
  bottom: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  padding-top: 17px;
  padding-left: 40px;
  transition: all 0.5s;
`;

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 16px;
`;

const Image = styled.img`
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);
  margin: 16px;
  border-radius: 16px;
`;

const ImgContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 24px;
`;

const Infrastructure = ({title, imgs}) => {
  const [ openImg, setOpenImg ] = useState(false);
  const [ imgShow, setImgShow ] = useState();
  const infraText = useRef();

  useEffect(() => {
    document.getElementById('titleInfrastructure').innerHTML = getHTMLfromText(title);
    infraText.current.innerHTML = getHTMLfromText('&nbsp;&nbsp;&nbsp;&nbsp;Explore a galeria de fotos do Instituto Empros e mergulhe em um mundo de aprendizado e descobertas. <br/>Nossas fotos capturam momentos inspiradores de nossas instalações modernas, salas de aula interativas e eventos emocionantes.<br/> Veja nossos alunos engajados em atividades práticas, colaborando em projetos inovadores e interagindo com nossos professores especializados. Cada imagem conta uma história única sobre a experiência de aprendizado no Instituto Empros. De palestras estimulantes a discussões animadas, nossas fotos refletem a atmosfera dinâmica e enriquecedora que oferecemos.<br/> Explore nossa galeria de fotos e deixe-se inspirar pelo poder do conhecimento e da educação no Instituto Empros.');
  },[title]);

  return (
    <Container>
      <Title id={'titleInfrastructure'} />
      <Text ref={infraText}/>
      <Grid style={{margin: '0 24px 24px 0', width: 'calc(100% - 24px)'}} container spacing={3}>
        {imgs.map(img => <Grid item spacing={3} sm={4} xs={12}>
          <Card onClick={async () => {
            await setImgShow(img);
            setOpenImg(true);
          }}>
            <Figure>
            <Img src={img.img} alt={img.name}/>
              <Figcaption>
                {img.name}
              </Figcaption>
            </Figure>
          </Card>
        </Grid>)}
      </Grid>
      <Modal open={openImg} onClose={() => setOpenImg(false)}>
        <ImgContainer onClick={() => setOpenImg(false)}>
          <Image alt={imgShow?.name} onClick={() => setOpenImg(false)} src={imgShow?.img}/>
        </ImgContainer>
      </Modal>
    </Container>
  );
};

export default Infrastructure;