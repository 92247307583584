import styled from "styled-components";
import {ContainerProps, InputStyledProps} from "./interface";
import {BorderRadius} from "../GlobalStyle";
import {getColor, getContrastColor} from "../Colors";

export const LabelStyle = styled.label<InputStyledProps>`
  color: ${getColor('neutral', 'a600')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  
  ${({disabled}) => disabled && `
    color: ${getColor('neutral', 'a500')};
  `}
`;

export const Required = styled.span<InputStyledProps>`
  color: ${getColor('error', 'a500')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  
  ${({disabled}) => disabled && `
    color: ${getColor('neutral', 'a500')};
  `}
`;

export const InputContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({isFullWidth}) => (isFullWidth ? '100%' : 'fit-content')};
`;
export const Container = styled.div<ContainerProps>`
  display: block;
  width: ${({isFullWidth}) => (isFullWidth ? '100%' : 'fit-content')};
  height: 52px;
`;

export const WrapperIcon = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${BorderRadius.medium};
  background: ${getColor('neutral', 'white')};
  bottom: 47px;
  left: calc(100% - 48px);
  width: 48px;
  height: 46px;
  cursor: text;
  
  svg {
    width: 22px;
    height: 22px;
    fill: ${getColor('primary','a300')};
    path {
      fill: ${getColor('primary','a300')};
    }
  }
`;

export const InputStyle = styled.input<InputStyledProps>`
  padding: ${({icon}) => !!icon ? '14px 49px 14px 17px' : '14px 17px'};
  border-radius: ${BorderRadius.medium};
  outline: 1px solid ${getColor('neutral', 'a600')};
  border: none;
  color: ${getContrastColor('neutral')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: ${({isFullWidth, icon}) => isFullWidth ? !!icon ? 'calc(100% - 66px)' : 'calc(100% - 35px)' : !!icon ? '268px' : '300px'};
  margin-top: 4px;
  margin-left: 1px;
  
  ::placeholder {
    color: ${getColor('neutral', 'a600')};
  }

  :hover {
    outline: 2px solid ${getColor('neutral', 'a600')};
  }
  
  :focus {
    outline: 2px solid ${getColor('primary', 'a300')};
  }
  
  :disabled {
    color: ${getColor('neutral', 'white')};
    outline: 2px solid ${getColor('neutral', 'a300')};
    background: ${getColor('transparent')};
    cursor: not-allowed;

    ::placeholder {
      color: ${getColor('neutral', 'white')};
    }
  }
`;