import React from 'react';
import {Container, InputContainer, InputStyle, LabelStyle, Required, WrapperIcon} from "./styled";
import {InputProps} from "./interface";
import Button from "../Button/Button";

const Input = (Props: InputProps) => {
  const {name, label, disabled, isFullWidth, placeholder, icon, type, required = false} = Props;
  return type !== 'file' ? (
    <InputContainer isFullWidth={isFullWidth}>
      <LabelStyle disabled={disabled} htmlFor={name}>{label} {required && <Required disabled={disabled}>*</Required>}</LabelStyle>
      <Container isFullWidth={isFullWidth} hasIcon={!!icon}>
        <InputStyle {...Props} name={name} id={name}/>
        {!!icon && !disabled && <WrapperIcon htmlFor={name}>{icon}</WrapperIcon>}
      </Container>
    </InputContainer>
  ) : (
    <InputContainer isFullWidth={isFullWidth}>
      <LabelStyle style={{marginBottom: '8px'}} disabled={disabled} htmlFor={name}>{label} {required && <Required disabled={disabled}>*</Required>}</LabelStyle>
      <input style={{display: 'none'}} {...Props} name={name} id={name}/>
      <Button isFullWidth={isFullWidth} color={'neutral'} colorVariant={'a600'} onClick={() => {
        document.getElementById(name)?.click();
      }} variant={'outlined'} title={placeholder}/>
    </InputContainer>
  );
};

export default Input;