import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {ArrowBackIosNew} from "@mui/icons-material";
import {COLORS} from "../../constants/COLORS";

const CarrouselContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-padding-left: ${({itemWidth, startGestalt, paddingLeft}) => startGestalt ? (itemWidth / 2) + 'px': paddingLeft ? paddingLeft : '0'};
  scroll-padding-right: ${({itemWidth}) => (itemWidth / 2) + 'px'};
  gap: ${({paddingLeftItem}) => paddingLeftItem ? '0' : '25px'};
  width: 100%;
  
  ::-webkit-scrollbar {
    height: 0;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: ${({width}) => width ? width : '100%'};
`;

const CarrouselItem = styled.div`
  flex: 0 0 ${({itemWidth}) => itemWidth + 'px'};
  scroll-snap-align: start;
  padding-left: ${({paddingLeftItem}) => paddingLeftItem ? paddingLeftItem : '0'};
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 2px;
  height: 60px;
  border-radius: 0 60px 60px 0;
  border: 1px solid #555555;
  margin-right: -36px;
  z-index: 2;
  background: ${COLORS.BLUE};
  filter: opacity(0.5);

  ${({left}) => left && `
      transform: rotate(180deg) translateX(36px);
    `
  }
`;

export const getId = event => {
  const path = event?.nativeEvent?.path || (event?.nativeEvent?.composedPath && event?.nativeEvent?.composedPath());
  if (path.find(child => child?.className?.toString().includes('carrouselItem')) !== undefined) {
    return Number(path.find(child => child?.className?.toString().includes('carrouselItem'))?.id);
  }
  return false;
};

const Carrousel = (
  {items,
    itemWidth,
    width,
    startGestalt,
    paddingLeft,
    paddingLeftItem,
    onChange,
    withArrow = false,
    infinite = false,
    autoStart = false,
    delay = 15000,
    forceUpdate = false,
  }) => {
  const ref = useRef();
  const [ internalItems, setInternalItems ] = useState([...items]);
  const [ index, setInd ] = useState(0);
  const [ isMouse, setIsMouse ] = useState(true);
  const [ onceTime, setOnceTime ] = useState(true);
  const [ oldLength, setOldLength ] = useState(0);
  const [ forceUpdateOld, setForceUpdate ] = useState(forceUpdate);
  const [ behaviorSmooth, setBehaviorSmooth ] = useState(false);

  const setScroll = (scroll) => {
    ref.current?.scrollTo({
      top: 0,
      left: scroll * (itemWidth + 25),
      behavior: behaviorSmooth ? 'smooth' : 'auto'
    })
  }

  const setIndex = ind => {
    setScroll(ind);
    setInd(ind);
  }

  useEffect(() => {
    onChange && onChange(index % items.length);
  },[index]);

  const handler = () => {
    setInd(value => {
      ref.current?.scrollTo({
        top: 0,
        left: ref.current.scrollLeft + itemWidth + 25,
        behavior: behaviorSmooth ? 'smooth' : 'auto'
      })
      return value
    });
  };

  useEffect(() => {
    let interval;
    if (autoStart && isMouse) {
      interval = setInterval(handler, delay);
    }
    return () => clearInterval(interval);
  },[autoStart, delay, isMouse, internalItems, behaviorSmooth]);

  useEffect(() => {
    if (infinite && onceTime && internalItems.length !== 0) {
      setOnceTime(false);
      const repeatCount = Math.ceil(1000 / items.length);
      const internalItemsTemp = internalItems.concat(...Array(repeatCount).fill(items));
      setInternalItems(internalItemsTemp);
      setTimeout(() => {
        setIndex(items.length * 10);
        setTimeout(() => {
          setBehaviorSmooth(true);
        }, 500)
      }, 500)
    }
  }, [infinite, internalItems, onceTime]);

  function containsInOrder(arr1, arr2) {
    let i = 0;
    let j = 0;
    while (i < arr1.length && j < arr2.length) {
      if (JSON.stringify(arr1[i].toString) === JSON.stringify(arr2[j].toString)) {
        j++;
      }
      i++;
    }
    return j === arr2.length;
  }

  useEffect(() => {
    if (items?.length === oldLength && forceUpdate === forceUpdateOld) {
      if (!containsInOrder(internalItems, items) && infinite) {
        setInternalItems([...items]);
        setOldLength(items?.length);
      }
    } else {
      setInternalItems([...items]);
      setOldLength(items?.length);
    }
  },[items, internalItems, oldLength, forceUpdate, forceUpdateOld]);

  useEffect(() => {
    setForceUpdate(forceUpdate);
  }, [forceUpdate]);

  const handleScroll = event => {
    const scrollX = ref.current.scrollLeft;
    setInd(Math.ceil(scrollX / (itemWidth + 25)));
  };

  return (
    <Container onMouseEnter={() => setIsMouse(false)} onMouseLeave={() => setTimeout(() => setIsMouse(true), 10000)} width={width}>
      {withArrow && <ArrowContainer onClick={() => setIndex(index - 1)}><ArrowBackIosNew style={{ color: COLORS.WHITE }}/></ArrowContainer>}
      <CarrouselContainer onScroll={handleScroll} paddingLeftItem={paddingLeftItem} startGestalt={startGestalt} paddingLeft={paddingLeft} itemWidth={itemWidth} ref={ref}>
        {Boolean(internalItems) && internalItems?.map((item, ind) => <CarrouselItem paddingLeftItem={paddingLeftItem} key={ind} id={ind} className={'carrouselItem'} itemWidth={itemWidth} onClick={() => setIndex(ind)}>
          {typeof item === 'function' ? item(ind, ind === index) : item}
        </CarrouselItem>)}
      </CarrouselContainer>
      {withArrow && <ArrowContainer left onClick={() => setIndex(index + 1)}><ArrowBackIosNew style={{ color: COLORS.WHITE }}/></ArrowContainer>}
    </Container>
  );
};

export default Carrousel;