import styled from "styled-components";
import {Colors, getColor, getCssColorProps} from "../Colors";
import {ButtonStyledProps} from "./interface";
import {BorderRadius} from "../GlobalStyle";

export const ButtonStyle = styled.button<ButtonStyledProps>`
  ${({color, colorVariant}) => getCssColorProps(color, colorVariant)};
  padding: 12px 16px;
  border-radius: ${BorderRadius.medium};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: none;
  width: ${({isFullWidth}) => (isFullWidth ? '100%' : 'fit-content')};
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none; !important
  user-select: none !important;
  
  :hover {
    cursor: pointer;
  }
  
  ${({variant, color, colorVariant}) => {
    switch (variant) {
      case 'filled':
        return `
          :hover {
            filter: brightness(0.9);
          }
          :disabled {
            background: ${Colors.neutral.a200};
            filter: brightness(1);
            color: ${Colors.neutral.a500};
            cursor: not-allowed;
          }
        `;
      case 'outlined':
        return `
          outline: 1px solid ${Colors.neutral.a400};
          background: ${Colors.neutral.white};
          color: ${getColor(color, colorVariant)};
          :hover {
            outline: 1px solid ${getColor(color, colorVariant)};
            background: ${getColor(color, 'a100')};
          }
          :disabled {
            outline: 1px solid ${Colors.neutral.a400};
            color: ${Colors.neutral.a500};
            background: ${Colors.neutral.a100};
            cursor: not-allowed;
          }
        `;
      case 'transparent':
        return `
          background: transparent;
          color: ${getColor(color, colorVariant)};
          :hover {
            background: ${getColor(color, 'a100')};
          }
          :disabled {
            color: ${Colors.neutral.a500};
            background: transparent;
            cursor: not-allowed;
          }
        `;
    }
  }}
  
  .is-loading {
    width: 0;
    height: 0;
    position: relative;
    left: calc(50% - 12px);
    bottom: 24px;
  }
  
  .children {
    width: 100%;
    display: flex;
    justify-content: center;
    
    div:not(.icon-left):not(.icon-right):not(.is-loading) {
      display: flex;
    }
  }
  
  .icon-left {
    margin-right: 8px;
    display: flex;
    height: auto;
    align-items: center;
  }

  .icon-left {
    display: flex;
    height: auto;
    align-items: center;
  }

  .icon-right {
    margin-left: 8px;
    display: flex;
    height: auto;
    align-items: center;
  }
  
  ${({isLoading}) => isLoading && `
    color: transparent;
    .icon-left, .icon-right {
      svg {
        fill: transparent;
        path {
          fill: transparent;
        }
      }
    }
  `}
`;