import {doc, getDoc} from "firebase/firestore";
import {database} from "./firebase";

var dataFix = null;

export const openLink = () => {
  if (dataFix !== null) {
    window.open(
      'https://api.whatsapp.com/send?phone=+55'
      + dataFix?.phones[0].replace('(', '').replace(') ', '').replace('-', '') +
      '&text=Ol%C3%A1%2C+cheguei+atrav%C3%A9s+do+site%21+%F0%9F%98%84%0A%0AGostaria+de+mais+informa%C3%A7%C3%B5es.',
      '_blank');
  }
};
export const getData = async (setData = () => {}) => {
  if (dataFix === null) {
    console.log('Get by: Firebase');
    const defaultObject = {
      headerText: 'O <b>CONHECIMENTO</b> TE LEVA ADIANTE!',
      coursesTitle: 'Nossos cursos',
      coursesText: '&nbsp;&nbsp;&nbsp;&nbsp;Descubra uma ampla variedade de cursos especializados no Empros - Instituto de Ensino e Pesquisa. <br> Nossa instituição oferece uma seleção abrangente de programas de especialização, aperfeiçoamento e imersões, projetados para atender às necessidades dos profissionais em busca de crescimento e desenvolvimento contínuo.<br> Com uma equipe de professores altamente qualificados e experientes, nossos cursos combinam teoria e prática para fornecer uma experiência de aprendizado enriquecedora. <br> Explore nossas opções de cursos e encontre o programa perfeito para impulsionar sua carreira e expandir seus horizontes. <br> Invista no seu futuro e embarque em uma jornada de aprendizado transformadora com a Empros.',
      teamTitle: '<b>Nossa equipe</b>',
      infrastructureTitle: '<b>Conheça Nossa estrutura e Dia a Dia</b>',
    };
    const information = await getDoc(doc(database, 'information','WhDgJncF2QaCE81i0cXs'));
    let dataTemp = {
      ...information.data(),
      links: {...information.data()},
      ...defaultObject
    }
    setData(dataTemp);
    const workers = await getDoc(doc(database, 'workers','HlKaEjEqcMfMNDLCy15W'));
    dataTemp = {
      ...information.data(),
      links: {...information.data()},
      team: workers.data().workers,
      ...defaultObject
    }
    setData(dataTemp);
    const curses = await getDoc(doc(database, 'curses', '7fWqnHwpiEUsNqORqLoh'));
    dataTemp = {
      ...information.data(),
      links: {...information.data()},
      team: workers.data().workers,
      courses: curses.data(),
      ...defaultObject
    }
    setData(dataTemp);
    dataFix = dataTemp;
    return dataTemp;
  } else {
    console.log('Get by: Cache');
    setData(dataFix);
    return dataFix;
  }
}