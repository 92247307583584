import React from 'react';
import styled from "styled-components";
import Button from "../../designSystem/Button/Button";
import Typography from "../../designSystem/Typography/Typography";
import {useNavigate} from "react-router-dom";

const MenuStyle = styled.div`
  .sidebar {
    position: fixed;
    width: 200px;
    height: 100%;
    background-color: #333;
    color: #fff;
    padding: 20px;
  }
`;

const Menu = () => {
  const navigate = useNavigate();
  return (
    <MenuStyle>
      <div className="sidebar">
        <Typography variant={"h3"} color={'neutral'} colorVariant={'white'} notIsContrastColor>Menu</Typography>
        <Button style={{margin: '16px 0'}} onClick={() => navigate('/admin/information')} isFullWidth color={'neutral'} colorVariant={'a200'} variant={'filled'} title={'Informações'}/>
        <Button style={{marginBottom: '16px'}} onClick={() => navigate('/admin/courses')} isFullWidth color={'neutral'} colorVariant={'a200'} variant={'filled'} title={'Cursos'}/>
        <Button style={{marginBottom: '16px'}} onClick={() => navigate('/admin/our-team')} isFullWidth color={'neutral'} colorVariant={'a200'} variant={'filled'} title={'Nosso Time'}/>
        <Button isFullWidth color={'neutral'} onClick={() => navigate('/admin')}  colorVariant={'a200'} variant={'filled'} title={'Contato'}/>
      </div>
    </MenuStyle>
  );
};

export default Menu;