import React, {useEffect, useState} from 'react';
import RestrictArea from "../../../common/adminComponents/RestrictArea/RestrictArea";
import {Grid, GridItem} from "../../../common/designSystem/Grid/Grid";
import Typography from "../../../common/designSystem/Typography/Typography";
import Input from "../../../common/designSystem/Input/Input";
import Button from "../../../common/designSystem/Button/Button";
import {Divider} from "@mui/material";
import {getListImageStorage, uploadSingleImageStorage} from "../../../common/services/Image";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../../common/services/firebase";
import {deleteObject, getStorage, ref} from "firebase/storage";
import {createDocument, updateDocument} from "../../../common/services/firestore";
import {enqueueSnackbar} from "notistack";

const OurTeam = () => {
  const [ workers, setWorkers ] = useState([]);
  const getData = async () => {
    const informationData = await getDoc(doc(database, 'workers','HlKaEjEqcMfMNDLCy15W'));
    setWorkers(informationData?.data()?.workers);
  }

  useEffect(() => {
    getData();
  },[]);
  const save = async () => {
    try {
      const data = {
        id: 'HlKaEjEqcMfMNDLCy15W',
        workers
      }
      const listImages = await getListImageStorage('workers');
      const imagesForRemove = listImages.filter(temp => workers.findIndex(worker => worker.img === temp.url) === -1);
      for (const imagesForRemoveKey in imagesForRemove) {
        const storage = getStorage();
        const storageRef = ref(storage, imagesForRemove[imagesForRemoveKey].path);
        await deleteObject(storageRef);
      }
      await updateDocument('workers', data);
      enqueueSnackbar('Salvo com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ops, tente novamente!', { variant: 'error' });
    }
  };

  return (
    <RestrictArea>
      <Grid style={{width: '100%', height: 'fit-content', paddingTop: '16px', paddingBottom: '16px'}} rowGapXs={16}>
        <GridItem xs={6}>
          <Typography variant={'h1'}>Nosso Time</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} alignItems={'center'}>
          <Typography variant={'body'} bold>Profissionais</Typography>
          <Button color={'info'} style={{marginLeft: '16px'}} variant={'outlined'} onClick={() => {
            if (workers.findIndex(worker => worker.name === '') === -1) {
              setWorkers(val => [...val, {name: '', img: '', description: ''}])
            }
          }} title={'Adicionar'}/>
        </GridItem>
        {workers.map((worker, index) => (
            <>
              <GridItem xs={6} sm={3} flexDirection={'row'} alignItems={'flex-end'}>
                <Input onChange={event => {
                  const newWorkers = [...workers];
                  newWorkers[index].name = event.target.value;
                  setWorkers(newWorkers);
                }} value={worker.name} isFullWidth name={'infraImageName' + worker.name} label={'Nome'} placeholder={'Exemplo'} />
              </GridItem>
              <GridItem xs={6} sm={3} flexDirection={'row'} alignItems={'flex-end'}>
                {worker?.img && <img height={80} width={80} onClick={() => window.open(worker?.img, '_blank')} style={{cursor: 'pointer', objectFit: 'contain', marginRight:'6px', border: '1px solid rgb(119 119 119)'}} src={worker?.img}/>}
                <Input onChange={async event => {
                  const url = await uploadSingleImageStorage(event, 'workers', index);
                  const newWorkers = [...workers];
                  newWorkers[index].img = url?.url;
                  setWorkers(newWorkers);
                }} isFullWidth type={'file'} name={'infraImage' + index} label={'Imagem'} placeholder={'Escolher Imagem'} />
              </GridItem>
              <GridItem xs={6} sm={6} flexDirection={'row'} alignItems={'flex-end'}>
                <Input onChange={event => {
                  const newWorkers = [...workers];
                  newWorkers[index].description = event.target.value;
                  setWorkers(newWorkers);
                }} value={worker.description} isFullWidth name={'infraImageDescription' + worker.name} label={'Descrição'} placeholder={'Descrição'} />
                <Button style={{marginLeft: '16px', height: '48px'}} color={'error'} onClick={() => {
                  const newWorkers = [...workers];
                  newWorkers.splice(newWorkers.findIndex(workerTemp => workerTemp.name === worker.name), 1);
                  setWorkers(newWorkers);
                }} variant={'transparent'} icon={'X'} />
              </GridItem>
              {index !== workers.length -1 && <GridItem xs={6}>
                <Divider/>
              </GridItem>}
            </>
          )
        )}
        <GridItem xs={0} sm={4}/>
        <GridItem xs={6} sm={2}>
          <Button isFullWidth color={'success'} variant={'filled'} onClick={async () => await save()} title={'Salvar'}/>
        </GridItem>
      </Grid>
    </RestrictArea>
  );
};

export default OurTeam;