import React, {useEffect} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import {getHTMLfromText} from "../../utils/text";

const Container = styled.div`
  width: calc(100% - 64px);
  height: 135px;
  background: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  padding: 0 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 24px;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    justify-content: flex-start;
  }
`;

const HeaderBottom = ({text}) => {
  useEffect(() => {
    document.getElementById('HeaderBottom').innerHTML = getHTMLfromText(text);
  },[text]);

  return (
    <Container id={'HeaderBottom'}/>
  );
};

export default HeaderBottom;