import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import {getHTMLfromText} from "../../utils/text";
import Carrousel from "../Carrousel/Carrousel";

const Container = styled.div`
  height: 550px;
  width: 100%;
  background: ${({isWorkers}) => isWorkers ? COLORS.WHITE : COLORS.BLUE};
  
  @media (max-width: 800px) {
    height: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: ${({isWorkers}) => isWorkers ? COLORS.BLUE : COLORS.WHITE};
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 52px 0 70px 0;

  @media (max-width: 800px) {
    text-align: left;
    width: calc(100% - 88px);
    padding: 44px 0 44px 44px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Item = styled.div`
  height: 350px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    height: 200px;
    width: 200px;
  }
`;

const ImageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({selected}) => selected ? '350px' : '235px'};
  width: ${({selected}) => selected ? '350px' : '235px'};
  border-radius: 175px;
  background: ${({isWorkers}) => isWorkers ? COLORS.BLUE : COLORS.WHITE_BORDER};
  transition: all 1.2s;
  cursor: pointer;
  outline: none !important;
  
  img {
    height: ${({selected}) => selected ? '335px' : '220px'};
    width: ${({selected}) => selected ? '335px' : '220px'};
    border-radius: 175px;
    transition: all 1.2s;
    object-fit: cover;
    outline: none !important;
  }

  @media (max-width: 800px) {
    height: ${({selected}) => selected ? '200px' : '144px'};
    width: ${({selected}) => selected ? '200px' : '144px'};

    img {
      height: ${({selected}) => selected ? '190px' : '132.32px'};
      width: ${({selected}) => selected ? '190px' : '132.32px'};
    }
  }
`;

const Member = styled.div`
  height: 350px;
  width: calc(50% - 180px);
  display: ${({isMobile}) => isMobile ? 'none' : 'flex'};
  justify-content: space-around;
  flex-direction: column;
  padding: 0 44px;
  
  @media (max-width: 800px) {
    display: ${({isMobile}) => isMobile ? 'flex' : 'none'};
    width: calc(100% - 88px);
    height: fit-content;
  }
`;

const Name = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${({isWorkers}) => isWorkers ? COLORS.BLUE : COLORS.WHITE};
`;

const Description = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: ${({isWorkers}) => isWorkers ? COLORS.BLUE : COLORS.WHITE};
`;

const Team = ({title, team = [], isWorkers = false}) => {
  const [ ind, setInd ] = useState(0);
  const { innerWidth } = window;

  useEffect(() => {
    document.getElementById('teamTitle').innerHTML = getHTMLfromText(title);
  },[title]);

  return (
    <Container isWorkers={isWorkers}>
      <Title id={'teamTitle'} isWorkers={isWorkers}/>
      <Content>
        <Member><Name isWorkers={isWorkers}>{team[ind]?.name}</Name><Description isWorkers={isWorkers} dangerouslySetInnerHTML={{__html:team[ind]?.description}}/></Member>
        <Carrousel
          autoStart
          infinite
          width={innerWidth > 800 ? 'calc(50% + 175px)' : '100%'}
          itemWidth={innerWidth > 800 ? 350 : 200}
          onChange={index => setInd(index)}
          paddingLeft={innerWidth > 800 ? '0' : '44px'}
          items={team.map(mem =>
              (id, selected) => <Item>
              <ImageContent isWorkers={isWorkers} selected={selected}>
                  <img draggable={false} src={mem?.img} alt={'Equipe'}/>
              </ImageContent>
            </Item>
          )}
        />
        <Member isMobile><Name isWorkers={isWorkers}>{team[ind]?.name}</Name><Description isWorkers={isWorkers} dangerouslySetInnerHTML={{__html:team[ind]?.description}}/></Member>
      </Content>
    </Container>
  );
};

export default Team;