export const COLORS = {
  BLUE: '#091D30',
  BLUE_LIGHT: '#103456',
  WHITE: '#ffffff',
  WHITE_LIGHT: '#808080',
  WHITE_BORDER: '#D9D9D9',
  ORANGE: '#ffb700',
  GREEN: '#6E9902',
  BLACK: '#242424',
  GRAY: '#F5F5F5'
};