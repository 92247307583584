import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import {createTheme, TextField, ThemeProvider} from "@mui/material";
import {createDocument} from "../../services/firestore";
import {enqueueSnackbar} from "notistack";
import {getHTMLfromText} from "../../utils/text";
import Button from "../../designSystem/Button/Button";

const Container = styled.div`
  width: calc(100% - 68px);
  background: ${COLORS.GRAY};
  border: 1px solid ${COLORS.BLACK};
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  padding: 34px;
  
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const ContainerLeft = styled.div`
  width: ${({isCurse}) => !isCurse ? '65%' : '100%'};
  border-right: 1px solid ${COLORS.BLACK};
  padding-right: 34px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  @media (max-width: 800px) {
    width: 100%;
    padding-right: 0;
    border-right: none;
  }
`;

const ContainerRight = styled.div`
  width: 35%;
  padding-left: 34px;
  display: ${({isCurse}) => !isCurse ? 'flex' : 'none'};
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0;
    padding-top: 16px;
  }
`;

const ContactInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 800px) {
    padding-top: 16px;
    align-items: flex-start;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  width: 100%;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.BLACK
    },
  },
})

const Contact = ({description = '', phones, address, email, contact, setContact, isCurse = false}) => {
  const [ isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    document.getElementById('ContactDescription').innerHTML = getHTMLfromText(description);
  },[description]);

  const save = async () => {
    if (!isNull(contact?.subject) && !isNull(contact?.email) && !isNull(contact?.phone) && !isNull(contact?.name)) {
      try {
        const contactTemp = {...contact, createAt: new Date(), read: false}
        await createDocument('contact', contactTemp);
        enqueueSnackbar('Enviado com sucesso!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Ops, tente novamente!', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Preencha todos os campos necessários', { variant: 'info' });
    }
    setIsSaved(true);
  }

  const isNull = val => {
    if (val === undefined || val === '') {
      return true;
    }
    return false;
  }

  return (
    <ThemeProvider theme={theme}>
    <Container>
      <ContainerLeft isCurse={isCurse}>
        <Title>Entre em Contato</Title>
        <TextField onChange={val =>
          setContact(value => {return {...value, name: val?.target?.value}})
        } error={isSaved && isNull(contact?.name)} value={contact?.name} variant={'filled'} label={'Nome: *'} fullWidth margin={'normal'}></TextField>
        <TextField onChange={val =>
          setContact(value => {return {...value, email: val?.target?.value}})
        } error={isSaved && isNull(contact?.email)} value={contact?.email} variant={'filled'} label={'Email: *'} name={'email'} type={'email'} fullWidth margin={'normal'}></TextField>
        <TextField onChange={val =>
          setContact(value => {return {...value, phone: val?.target?.value}})
        } error={isSaved && isNull(contact?.phone)} value={contact?.phone} variant={'filled'} label={'Telefone: *'} fullWidth margin={'normal'}></TextField>
        <TextField onChange={val =>
          setContact(value => {return {...value, subject: val?.target?.value}})
        } error={isSaved && isNull(contact?.subject)} value={contact?.subject} focused={Boolean(contact?.subject)} variant={'filled'} label={'Assunto: *'} fullWidth margin={'normal'}></TextField>
        <TextField onChange={val =>
          setContact(value => {return {...value, comment: val?.target?.value}})
        } variant={'filled'} label={'Comentário:'} rows={6} multiline fullWidth margin={'normal'}></TextField>
        <Button title={'Enviar'} onClick={() => save()} color={'success'} variant={'filled'}/>
      </ContainerLeft>
      <ContainerRight isCurse={isCurse}>
        <Description id={'ContactDescription'}/>
        <ContactInfo>
        {phones.map(phone => <Description>
          {phone}
          </Description>)}
          <Description>{address?.street}, n° {address?.number}</Description>
          <Description>{address?.city} - {address?.district}</Description>
          <Description>{address?.state}</Description>
          <Description>{email}</Description>
        </ContactInfo>
      </ContainerRight>
    </Container>
    </ThemeProvider>
  );
};

export default Contact;