import React, {useEffect, useState} from 'react';
import {Grid, GridItem} from "../../../common/designSystem/Grid/Grid";
import Typography from "../../../common/designSystem/Typography/Typography";
import Input from "../../../common/designSystem/Input/Input";
import RestrictArea from "../../../common/adminComponents/RestrictArea/RestrictArea";
import Button from "../../../common/designSystem/Button/Button";
import {Divider} from "@mui/material";
import {getListImageStorage, uploadSingleImageStorage} from "../../../common/services/Image";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../../common/services/firebase";
import {updateDocument} from "../../../common/services/firestore";
import {deleteObject, getStorage, ref} from "firebase/storage";
import {enqueueSnackbar} from "notistack";

const Courses = () => {
  const [ areas, setAreas ] = useState([]);
  const [ courses, setCourses ] = useState([]);
  const getData = async () => {
    const informationData = await getDoc(doc(database, 'curses','7fWqnHwpiEUsNqORqLoh'));
    setAreas(informationData?.data()?.areas);
    setCourses(informationData?.data()?.courses);
  }

  useEffect(() => {
    getData();
  },[]);
  const save = async () => {
    try {
      const data = {
        id: '7fWqnHwpiEUsNqORqLoh',
        areas,
        courses
      }
      const listImages = await getListImageStorage('courses');
      const imagesForRemove = listImages.filter(temp => courses.findIndex(course => course.img === temp.url) === -1);
      for (const imagesForRemoveKey in imagesForRemove) {
        const storage = getStorage();
        const storageRef = ref(storage, imagesForRemove[imagesForRemoveKey].path);
        await deleteObject(storageRef);
      }
      await updateDocument('curses', data);
      enqueueSnackbar('Salvo com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ops, tente novamente!', { variant: 'error' });
    }
  };

  return (
    <RestrictArea>
      <Grid style={{width: '100%', height: 'fit-content', paddingTop: '16px', paddingBottom: '16px'}} rowGapXs={16}>
        <GridItem xs={6}>
          <Typography variant={'h1'}>Cursos</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} alignItems={'center'}>
          <Typography variant={'h3'}>Áreas</Typography>
          <Button color={'info'} style={{marginLeft: '16px'}} onClick={() => {
            if (areas.findIndex(area => area?.name === '') === -1) {
              setAreas(val => [...val, {id: areas?.length, name: ''}])
            }
          }} variant={'outlined'} title={'Adicionar'}/>
        </GridItem>
        {areas.map((area, index) => (
          <GridItem xs={6} sm={2} key={index} flexDirection={'row'} alignItems={'flex-end'}>
            <Input
              value={area.name}
              onChange={(e) => {
                const newAreas = [...areas];
                newAreas[index].name = e.target.value;
                setAreas(newAreas);
                const newCourses = [...courses];
                newCourses.map(course => {
                  if (course.area.id === index) {
                    course.area.name = e.target.value;
                  }
                  return course;
                });
                setCourses(newCourses);
              }}
              label={'Area'}
              isFullWidth
              placeholder={'Area'}
            />
            <Button style={{marginLeft: '16px', height: '48px'}} color={'error'} onClick={() => {
              const newAreas = [...areas];
              newAreas.splice(newAreas.findIndex(areaTemp => areaTemp?.id === area?.id), 1);
              setAreas(newAreas);
            }} variant={'transparent'} icon={'X'} />
          </GridItem>
        ))}
        <GridItem xs={6}><Divider/></GridItem>
        <GridItem xs={6}>
          {areas.filter(area => area.name !== '').sort((a, b) => b.id - a.id).map((area, ind) => (
            <Grid color={"neutral"} colorVariant={ind%2 === 0 ? 'a300' : 'white'}>
              <GridItem marginXl={'16px 0 16px 0'} xs={6} key={ind} flexDirection={'row'} alignItems={'center'}>
                <Typography variant={'body'} bold>{area.name}</Typography>
                <Button color={'info'} style={{marginLeft: '16px'}} onClick={() => {
                  if (courses.findIndex(course => course.area === area && course.name === '') === -1) {
                    setCourses(val => [...val, {area, name: ''}])
                  }
                }} variant={'outlined'} title={'Adicionar Curso'}/>
              </GridItem>
              {courses.map((course, index) => (
                course.area.id === area.id && (
                  <>
                    <GridItem marginXl={'0 0 16px 0'} xs={6} sm={2} key={index + '1'} flexDirection={'row'} alignItems={'flex-end'}>
                      <Input
                        value={course.name}
                        onChange={(e) => {
                          const newCourses = [...courses];
                          newCourses[index].name = e.target.value;
                          setCourses(newCourses);
                        }}
                        label={'Nome'}
                        isFullWidth
                        placeholder={'Nome do Curso'}
                      />
                    </GridItem>
                    <GridItem marginXl={'0 0 16px 0'} xs={6} sm={2} key={index + '2'} flexDirection={'row'} alignItems={'flex-end'}>
                      <Input
                        value={course.description}
                        onChange={(e) => {
                          const newCourses = [...courses];
                          newCourses[index].description = e.target.value;
                          setCourses(newCourses);
                        }}
                        label={'Descrição'}
                        isFullWidth
                        placeholder={'Descrição do Curso'}
                      />
                    </GridItem>
                    <GridItem marginXl={'0 0 16px 0'} xs={6} sm={2} key={index + '3'} flexDirection={'row'} alignItems={'flex-end'}>
                      {course?.img && <img height={80} width={80} onClick={() => window.open(course?.img, '_blank')} style={{cursor: 'pointer', objectFit: 'contain', marginRight:'6px', border: '1px solid rgb(119 119 119)'}} src={course?.img}/>}
                      <Input onChange={async event => {
                          const url = await uploadSingleImageStorage(event, 'courses', index +'-'+course.area.id);
                          const newCurses = [...courses];
                          newCurses[index].img = url?.url;
                          setCourses(newCurses);
                        }}
                        type={'file'}
                        label={'Imagem'}
                        name={'image'+index}
                        isFullWidth
                        placeholder={'Imagem do Curso'}
                      />
                      <Button style={{marginLeft: '16px', height: '48px'}} color={'error'} onClick={() => {
                        const newCourses = [...courses];
                        newCourses.splice(newCourses.findIndex(courseTemp => courseTemp.name === course.name), 1);
                        setCourses(newCourses);
                      }} variant={'transparent'} icon={'X'} />
                    </GridItem>
                  </>
                )
              ))}
            </Grid>
          ))}
        </GridItem>
        <GridItem xs={0} sm={4}/>
        <GridItem xs={6} sm={2}>
          <Button isFullWidth color={'success'} variant={'filled'} onClick={async () => await save()} title={'Salvar'}/>
        </GridItem>
      </Grid>
    </RestrictArea>
  );
};

export default Courses;