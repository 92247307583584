import React, {useEffect, useState} from 'react';
import {DialogProps} from "./interface";
import {ButtonContainer, DialogContainer, DialogPage} from "./styled";
import {Grid, GridItem} from '../Grid/Grid';
import Typography from "../Typography/Typography";
import Close from "../Icons/Close";
import Button from "../Button/Button";

const Dialog = (Props: DialogProps) => {
  const {open, title, children, setOpen, buttonLabelConfirm, buttonLabelCancel, isObligatory, onConfirm, onCancel} = Props;
  const [ vibrate, setVibrate ] = useState(false);
  const onConfirmClick = () => {
    onConfirm();
  }

  const onCancelClick = () => {
    onCancel();
    setOpen(false);
  }

  const handleClose = (event: any) => {
    if (event.target.className.includes('dialog-page')) {
      if (!isObligatory) {
        return onCancelClick();
      }
      setVibrate(true);
      setTimeout(() => {
        setVibrate(false);
      } , 450);
    }
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <DialogPage open={open} className={'dialog-page'} onClick={event => handleClose(event)}>
      <DialogContainer className={vibrate ? 'vibrate' : ''}>
        <Grid noMargin>
          <GridItem xs={5}><Typography variant={'h3'}>{title}</Typography></GridItem>
          <GridItem xs={1} alignItems={'flex-end'}><ButtonContainer onClick={() => onCancelClick()}><Close/></ButtonContainer></GridItem>
          <GridItem xs={6} marginXs={'16px 0 24px 0'}>{children}</GridItem>
          <GridItem xs={3} alignItems={'flex-end'}><Button color={'error'} onClick={() => onCancelClick()} variant={'transparent'} title={buttonLabelCancel}/></GridItem>
          <GridItem xs={3}><Button color={'primary'} onClick={() => onConfirmClick()} variant={'filled'} title={buttonLabelConfirm}/></GridItem>
        </Grid>
      </DialogContainer>
    </DialogPage>
  );
};

export default Dialog;