import React, {useEffect, useState} from 'react';
import RestrictArea from "../../../common/adminComponents/RestrictArea/RestrictArea";
import {Grid, GridItem} from "../../../common/designSystem/Grid/Grid";
import Typography from "../../../common/designSystem/Typography/Typography";
import Input from "../../../common/designSystem/Input/Input";
import Button from "../../../common/designSystem/Button/Button";
import {Divider} from "@mui/material";
import {updateDocument} from "../../../common/services/firestore";
import {doc, getDoc} from 'firebase/firestore';
import {database} from "../../../common/services/firebase";
import {getListImageStorage, uploadSingleImageStorage} from "../../../common/services/Image";
import {getStorage, ref, deleteObject} from "firebase/storage";
import {enqueueSnackbar} from "notistack";

const Information = () => {
  const [ phones, setPhones ] = useState(['']);
  const [ infraImages, setInfraImages ] = useState([]);
  const [ data, setData ] = useState({
    id: 'WhDgJncF2QaCE81i0cXs',
    instagram: '',
    facebook: '',
    youtube: '',
    phones: [],
    email: '',
    description: '',
    street: '',
    number: '',
    district: '',
    city: '',
    state: '',
    infraDescription: '',
    infraImages: []
  });

  const setDataField = (field, value) => {
    const newData = {...data};
    newData[field] = value;
    setData(newData);
  }

  useEffect(() => {
    setData(val => {
      const newData = {...val};
      newData.phones = phones;
      newData.infraImages = infraImages;
      return newData;
    });
  },[phones, infraImages])

  const getData = async () => {
    const information = await getDoc(doc(database, 'information','WhDgJncF2QaCE81i0cXs'));
    setPhones(information.data().phones);
    setInfraImages(information.data().infraImages);
    setData(information.data());
  }

  useEffect(() => {
    getData();
  },[]);

  const save = async () => {
    try {
      const listImages = await getListImageStorage('infraImages');
      const imagesForRemove = listImages.filter(temp => infraImages.findIndex(image => image.img === temp.url) === -1);
      for (const imagesForRemoveKey in imagesForRemove) {
        const storage = getStorage();
        const storageRef = ref(storage, imagesForRemove[imagesForRemoveKey].path);
        await deleteObject(storageRef);
      }
      await updateDocument('information', data);
      await getData();
      enqueueSnackbar('Salvo com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Ops, tente novamente!', { variant: 'error' });
    }
  }

  return (
    <RestrictArea>
      <Grid style={{width: '100%', height: 'fit-content', paddingTop: '16px', paddingBottom: '16px'}} rowGapXs={16}>
        <GridItem xs={6}>
          <Typography variant={'h1'}>Informações</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'h3'}>Redes Sociais</Typography>
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Input onChange={event => setDataField('instagram', event?.target?.value)} value={data?.instagram} isFullWidth name={'instagram'} label={'Instagram'} placeholder={'https://instagram.com'} />
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Input onChange={event => setDataField('facebook', event?.target?.value)} value={data?.facebook} isFullWidth name={'facebook'} label={'Facebook'} placeholder={'https://facebook.com'} />
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Input onChange={event => setDataField('youtube', event?.target?.value)} value={data?.youtube} isFullWidth name={'youtube'} label={'Youtube'} placeholder={'https://youtube.com'} />
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} alignItems={'center'}>
          <Typography variant={'h3'}>Telefones</Typography>
          <Button color={'info'} style={{marginLeft: '16px'}} onClick={() => {
            if (phones.findIndex(phone => phone === '') === -1 && phones.length < 2) {
              setPhones(val => [...val, ''])
            }
          }} variant={'outlined'} title={'Adicionar'}/>
        </GridItem>
        {phones && phones.map((id, index) => (
          <GridItem xs={6} sm={2} flexDirection={'row'} alignItems={'flex-end'}>
            <Input onChange={event => {
              const newPhones = [...phones];
              newPhones[index] = event.target.value;
              setPhones(newPhones);}
            } value={id} isFullWidth name={'phone' + id} label={'Número'} placeholder={'(48) 9 9999-9999'} />
            <Button style={{marginLeft: '16px', height: '48px'}} color={'error'} onClick={() => {
              const newPhones = [...phones];
              newPhones.splice(newPhones.findIndex(phone => phone === id), 1);
              setPhones(newPhones);
            }} variant={'transparent'} icon={'X'} />
          </GridItem>
        ))}
        <GridItem xs={6}>
          <Typography variant={'h3'}>Contato</Typography>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('email', event?.target?.value)} value={data?.email} isFullWidth name={'email'} label={'Email'} placeholder={'exemplo@empros.com.br'} />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('description', event?.target?.value)} value={data?.description} isFullWidth name={'descripton'} label={'Descrição'} placeholder={''} />
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'h3'}>Endereço</Typography>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('street', event?.target?.value)} value={data?.street} isFullWidth name={'street'} label={'Rua'} placeholder={'Rua Exemplo'} />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('number', event?.target?.value)} value={data?.number} isFullWidth name={'number'} label={'Número'} placeholder={'156'} />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('district', event?.target?.value)} value={data?.district} isFullWidth name={'district'} label={'Bairro'} placeholder={'Centro'} />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('city', event?.target?.value)} value={data?.city} isFullWidth name={'city'} label={'Cidade'} placeholder={'Tubarão'} />
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('state', event?.target?.value)} value={data?.state} isFullWidth name={'state'} label={'Estado'} placeholder={'Santa Catarina'} />
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'h3'}>Infraestrutura</Typography>
        </GridItem>
        <GridItem xs={6} sm={3}>
          <Input onChange={event => setDataField('infraDescription', event?.target?.value)} value={data?.infraDescription} isFullWidth name={'infraDescription'} label={'Descrição'} placeholder={''} />
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} alignItems={'center'}>
          <Typography variant={'body'} bold>Setores | Imagens</Typography>
          <Button color={'info'} style={{marginLeft: '16px'}} variant={'outlined'} onClick={() => {
            if (infraImages.findIndex(image => image.name === '') === -1 && infraImages.length < 6) {
              setInfraImages(val => [...val, {name: '', img: '', description: ''}])
            }
          }} title={'Adicionar'}/>
        </GridItem>
        {infraImages.map((image, index) => (
            <>
              <GridItem xs={6} sm={3} flexDirection={'row'} alignItems={'flex-end'}>
                <Input onChange={event => {
                  const newInfraImages = [...infraImages];
                  newInfraImages[index].name = event.target.value;
                  setInfraImages(newInfraImages);
                }} value={image?.name} isFullWidth name={'infraImageName' + image.name} label={'Nome'} placeholder={'Exemplo'} />
              </GridItem>
              <GridItem xs={6} sm={3} flexDirection={'row'} alignItems={'flex-end'}>
                {image?.img && <img height={80} width={80} onClick={() => window.open(image?.img, '_blank')} style={{cursor: 'pointer', objectFit: 'contain', marginRight:'6px', border: '1px solid rgb(119 119 119)'}} src={image?.img}/>}
                <Input onChange={async event => {
                  const url = await uploadSingleImageStorage(event, 'infraImages', index);
                  const newInfraImages = [...infraImages];
                  newInfraImages[index].img = url?.url;
                  setInfraImages(newInfraImages);
                }} isFullWidth type={'file'} name={'infraImage' + image.name} label={'Imagem'} placeholder={'Escolher Imagem'} />
              </GridItem>
              <GridItem xs={6} sm={6} flexDirection={'row'} alignItems={'flex-end'}>
                <Input onChange={event => {
                  const newInfraImages = [...infraImages];
                  newInfraImages[index].description = event.target.value;
                  setInfraImages(newInfraImages);
                }} value={image?.description} isFullWidth name={'infraImageDescription' + image.name} label={'Descrição'} placeholder={'Descrição'} />
                <Button style={{marginLeft: '16px', height: '48px'}} color={'error'} onClick={() => {
                  const newInfraImages = [...infraImages];
                  newInfraImages.splice(newInfraImages.findIndex(imageTemp => imageTemp.name === image.name), 1);
                  setInfraImages(newInfraImages);
                }} variant={'transparent'} icon={'X'} />
              </GridItem>
              {index !== infraImages.length -1 && <GridItem xs={6}>
                <Divider/>
              </GridItem>}
            </>
          )
        )}
        <GridItem xs={0} sm={4}/>
        <GridItem xs={6} sm={2}>
          <Button isFullWidth color={'success'} variant={'filled'} onClick={async () => await save()} title={'Salvar'}/>
        </GridItem>
      </Grid>
    </RestrictArea>
  );
};

export default Information;