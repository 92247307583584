import React, {useEffect} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import Menu from "../Menu/Menu";
import {getHTMLfromText} from "../../utils/text";

const Container = styled.div`
  width: calc(100% - 320px);
  background: ${COLORS.BLUE};
  display: flex;
  flex-direction: column;
  padding: 35px 160px;
  
  @media (max-width: 800px) {
    display: none;
  }
`;

const Contact = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.WHITE};
  justify-content: space-between;
  margin-top: 60px;
`;

const Description = styled.div`
  max-width: 550px;
  padding-right: 16px;
  @media (max-width: 800px) {
    display: none;
    max-width: 275px;
    padding-right: 0;
  }
`;

const ContactInfo = styled.div`
  max-width: 275px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Text = styled.div`
  text-align: right;
`;

const Footer = ({description = '', address, email, selected = 0}) => {
  useEffect(() => {
    document.getElementById('FooterDescription').innerHTML = getHTMLfromText(description);
  },[description]);

  return (
    <Container>
      <Menu isFooter selected={selected}/>
      <Contact>
        <Description id={'FooterDescription'}/>
        <ContactInfo>
          <Text>{address?.street}, n° {address?.number}</Text>
          <Text>{address?.city} - {address?.district}</Text>
          <Text>{address?.state}</Text>
          <Text>{email}</Text>
        </ContactInfo>
      </Contact>
    </Container>
  );
};

export default Footer;