import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDsYPcDMaNJTdCqedK_Nd5QEGox4C8Jje8",
  authDomain: "empros-eec56.firebaseapp.com",
  projectId: "empros-eec56",
  storageBucket: "empros-eec56.appspot.com",
  messagingSenderId: "254929178154",
  appId: "1:254929178154:web:4519fa184836f992f9bd53",
  measurementId: "G-3QC35KJZQX"
};

export const appFirebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(appFirebase);
export const storage = getStorage(appFirebase);
export const messaging = getMessaging(appFirebase);
export const database = getFirestore();
