import React from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import { HashLink as Link } from 'react-router-hash-link';

const MenuContainer = styled.div`
  width: ${({isFooter}) => isFooter ? '100%' : 'calc(100% - 45px)'};
  margin: ${({isFooter}) => isFooter ? 'none' : '45px 60px 0 0'};
  display: flex;
  justify-content: ${({isFooter, simple}) => isFooter ? 'space-between' : simple ? 'center' : 'flex-end'};
  
  @media (max-width: 800px) {
    display: none;
  }
`;

const MenuItem = styled.h2`
  margin: 0;
  line-height: 20px;
  height: 30px;
  cursor: pointer;
  color: ${({isFooter}) => isFooter ? COLORS.WHITE : COLORS.BLUE};
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  transition: all 0.2s;
  ${({simple}) => !simple && 'text-shadow: 0 0 20px ${COLORS.BLUE};'}
  padding: 0 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  ${({selected, isFooter}) => selected && `
      border-bottom: 2px solid ${isFooter ? COLORS.WHITE : COLORS.BLUE};
    `
}
  
  ${({selected, isFooter}) => !selected && `
    :hover {
      border-bottom: 2px solid ${isFooter ? COLORS.WHITE : COLORS.BLUE};
    }`
}
`;

const Divider = styled.div`
  margin: 0 20px;
  width: 3px;
  height: 20px;
`;

const Menu = ({isFooter, simple = false, selected = 0}) => {
  return (
    <MenuContainer isFooter={isFooter} simple={simple}>
      <Link to={'/'} smooth style={{textDecoration: 'none'}}>
        <MenuItem isFooter={isFooter} simple={simple} selected={selected === 0}>
            HOME
        </MenuItem>
      </Link>
      <Divider/>
      <Link to={'/cursos'} smooth style={{textDecoration: 'none'}}>
        <MenuItem isFooter={isFooter} simple={simple} selected={selected === 1}>
          CURSOS
        </MenuItem>
      </Link>
      <Divider/>
      <Link to={'/quem-somos'} smooth style={{textDecoration: 'none'}}>
        <MenuItem isFooter={isFooter} simple={simple} selected={selected === 2}>
          QUEM SOMOS
        </MenuItem>
      </Link>
      <Divider/>
      <Link to={'/profissionais'} smooth style={{textDecoration: 'none'}}>
        <MenuItem isFooter={isFooter} simple={simple} selected={selected === 3}>
          PROFISSIONAIS
        </MenuItem>
      </Link>
      <Divider/>
      <Link to={'/#contato'} smooth style={{textDecoration: 'none'}}>
        <MenuItem isFooter={isFooter} simple={simple} selected={selected === 4}>
          CONTATO
        </MenuItem>
      </Link>
    </MenuContainer>
  );
};

export default Menu;