import React from 'react';
import {IconProps} from "./interface";

const Close = ({width, height, color, colorVariant, isContrastColor}: IconProps) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="3" fill="#EEF1F6"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7891 6.21088C18.0703 6.49206 18.0703 6.94794 17.7891 7.22912L13.0185 12L17.7891 16.7709C18.0703 17.0521 18.0703 17.5079 17.7891 17.7891C17.5079 18.0703 17.0521 18.0703 16.7709 17.7891L12 13.0185L7.22912 17.7891C6.94794 18.0703 6.49206 18.0703 6.21088 17.7891C5.92971 17.5079 5.92971 17.0521 6.21088 16.7709L10.9815 12L6.21088 7.22912C5.92971 6.94794 5.92971 6.49206 6.21088 6.21088C6.49206 5.92971 6.94794 5.92971 7.22912 6.21088L12 10.9815L16.7709 6.21088C17.0521 5.92971 17.5079 5.92971 17.7891 6.21088Z" fill="#121417"/>
    </svg>
  );
};

export default Close;