import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import Carrousel from "../Carrousel/Carrousel";
import {COLORS} from "../../constants/COLORS";
import {getHTMLfromText, textToURL} from "../../utils/text";
import {doc, getDoc} from "firebase/firestore";
import {database} from "../../services/firebase";
import {Link} from "react-router-dom";
import {getData} from "../../services/data";

const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding: 10px 24px;
`;

const Text = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  padding: 10px 24px;
`;

const Card = styled.div`
  border-radius: 16px;
  height: 495px;
  border: 2px solid ${COLORS.WHITE_LIGHT};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: #F2F2F2;
  flex-direction: column;
  cursor: pointer;
`;

const CarrouselContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 74px;

  //@media (max-width: 800px) {
  //  width: calc(100% - 24px);
  //  margin-left: 24px;
  //}
`;

const CardContent = styled.div`
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CardImage = styled.img`
  width: 100%;
  height: 277px;
  border-radius: 14px 14px 0 0;
  filter: brightness(0.8);
  object-fit: fill;
`;

const CardTitle = styled.h2`
  width: calc(100% - 44px);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 18px 22px;
`;

const CardDescription = styled.h3`
  width: calc(100% - 44px);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin: 0 22px;
`;

const CardButton = styled.h2`
  width: calc(100% - 88px);
  border: 1px solid ${COLORS.GREEN};
  color: ${COLORS.GREEN};
  margin: 18px 44px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  padding: 10px 0;
  text-transform: uppercase;
  border-radius: 8px;
  cursor: pointer;
  
  :hover {
    background: ${COLORS.WHITE};
  }
`;

const ListArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  flex-direction: row;
  justify-content: center;
`;

const Courses = ({title, text, setContact, areaNameFilter = undefined, isHome = false}) => {
  const { innerWidth } = window;
  const screen = document.getElementById('root');
  const [ courses, setCourses ] = useState([]);
  const [ areas, setAreas ] = useState([]);
  const [ forceUpdate, setForceUpdate ] = useState(false);
  const coursesTitle = useRef();
  const coursesText = useRef();

  const hasAreaFilter = areaNameFilter !== undefined;

  useEffect(() => {
    coursesTitle.current.innerHTML = getHTMLfromText(hasAreaFilter ? areaNameFilter : title);
    coursesText.current.innerHTML = getHTMLfromText(hasAreaFilter ? '' : text);
   },[title, text, hasAreaFilter, coursesTitle, coursesText, areaNameFilter]);

  const getCourses = async () => {
    const coursesTemp = (await getData())?.courses;
    const filtered = areaNameFilter === undefined ? coursesTemp.courses : coursesTemp.courses
      .filter(course => textToURL(course.area.name) === textToURL(areaNameFilter));
    setCourses([...filtered]);
    setAreas([...coursesTemp?.areas]);
    if (hasAreaFilter){
      setForceUpdate(!forceUpdate);
    }
  }

  useEffect(() => {
    getCourses();
  },[areaNameFilter]);

  const reduceText = text => {
    if (text?.length >= 120) {
      return text.slice(0, 120) + '...'
    } else {
      return text
    }
  }

  return (
    <>
      <Title ref={coursesTitle}/>
      <Text ref={coursesText}/>
      {isHome && <div style={{display: 'flex', padding: '0 24px 0 24px', margin: '0 0 40px 0', alignItems: 'flex-start', alignContent: 'space-around', flexDirection: 'column'}}>
        <ListArea>
          {areas?.map(area => <Link style={{padding: '6px', margin: '8px', background: 'rgb(243 249 255)', border: '1px solid #091D30', borderRadius: '8px'}} to={`/cursos/${textToURL(area?.name)}`}>{area?.name}</Link>)}
        </ListArea>
      </div>}
      <CarrouselContainer>
        <Carrousel autoStart itemWidth={281} forceUpdate={forceUpdate} paddingLeftItem={hasAreaFilter || innerWidth < 800 ? '22px' : false} withArrow={innerWidth > 800} infinite={!hasAreaFilter} paddingLeft={innerWidth > 800 ? '25px' : '0'}
                   items={[...courses.map(course =>
          <Link to={`/cursos/${textToURL(course.area.name)}/${textToURL(course.name)}`} style={{width: '100%'}}><Card>
            <div style={{width: '100%'}}>
            <CardImage src={course?.img}></CardImage>
            </div>
            <CardContent>
              <CardTitle>{course?.name}</CardTitle>
              <CardDescription>{reduceText(course?.description)}</CardDescription>
            </CardContent>
              <CardButton onClick={() => {
                setContact(value => {return {...value, subject: 'Interesse no curso: ' + course?.name}});
              }}>Ver Mais</CardButton>

          </Card></Link>
        )]}/>
      </CarrouselContainer>
    </>
  );
};

export default Courses;