import React from 'react';
import styled from "styled-components";
import banner from "../../../static/img/banner.png"
import logo from "../../../static/img/Logo.webp"
import Menu from "../Menu/Menu";

const Container = styled.div`
  display: flex;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-direction: column;
  
  @media (min-width: 1180px) {
    height: 740px;
  }
  
  @media (max-width: 1180px) {
    height: 620px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 60px;
  
  @media (min-width: 1180px) {
    height: calc(740px - 32px - 45px);
  }

  @media (max-width: 1180px) {
    height: calc(620px - 32px - 45px);
  }

  @media (max-width: 800px) {
    margin-left: 8px;
    margin-top: 8px;
    height: 100px;
    
    img {
      height: 100%;
      width: fit-content;
    }
  }
`;

const Banner = () => {
  return (
    <Container>
      <Menu/>
      <LogoContainer>
        <img draggable={false} width={324} height={463} src={logo} alt={'Logo Empros'}/>
      </LogoContainer>
    </Container>
  );
};

export default Banner;