import React, {useState} from 'react';
import styled from "styled-components";
import {COLORS} from "../../constants/COLORS";
import {FacebookOutlined, Instagram, Menu, YouTube} from "@mui/icons-material";
import {Modal} from "@mui/material";
import {HashLink} from 'react-router-hash-link';

const Container = styled.div`
  width: calc(100% - 64px);
  height: 60px;
  background: ${COLORS.BLUE};
  color: ${COLORS.WHITE};
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  @media (max-width: 800px) {
    justify-content: ${({isFooter}) => isFooter ? 'center' : 'space-between'};
  }
`;

const SubContainer = styled.div`
  display: flex;
  
  @media (max-width: 800px) {
    display: ${({isFooter}) => isFooter ? 'flex' : 'none'};
  }
`;

const MenuMobile = styled.div`
  display: none;
  align-items: center;
  
  @media (max-width: 800px) {
    display: ${({isFooter}) => isFooter ? 'none' : 'flex'};
  }
`;

const ContainerMenu = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  min-width: 250px;
  background: ${COLORS.WHITE};
  outline: none;
  flex-direction: column;
`;

const Link = styled(HashLink)`
  padding: 16px;
  width: calc(100% - 32px);
  border-bottom: 1px solid ${COLORS.BLACK};
  ${({selected}) => selected && `background: ${COLORS.WHITE_BORDER};`}
  
  :hover {
    background: ${COLORS.GRAY};
  }
`;

const HeaderTop = ({links, phones, isFooter, selected = 0}) => {
  const [ openMenu, setOpenMenu ] = useState(false);

  const openLink = (link) => {
    window.open(
      link,
      '_blank');
  };

  return (
    <Container isFooter={isFooter}>
      <Modal style={{outline:'none'}} open={openMenu} onClose={() => setOpenMenu(false)}>
        <ContainerMenu>
          <Link to={'/'} onClick={() => setOpenMenu(false)} smooth selected={selected === 0}>
            Home
          </Link>
          <Link to={'/cursos'} onClick={() => setOpenMenu(false)} smooth selected={selected === 1}>
            Cursos
          </Link>
          <Link to={'/quem-somos'} onClick={() => setOpenMenu(false)} smooth selected={selected === 2}>
            Quem Somos
          </Link>
          <Link to={'/profissionais'} onClick={() => setOpenMenu(false)} smooth selected={selected === 3}>
            Profissionais
          </Link>
          <Link to={'/#contato'} onClick={() => setOpenMenu(false)} smooth selected={selected === 4}>
            Contato
          </Link>
        </ContainerMenu>
      </Modal>
      <MenuMobile isFooter={isFooter} onClick={() => setOpenMenu(true)}>
        <Menu style={{padding: '12px', margin: '0 8px 0 -24px'}}/>
        Empros
      </MenuMobile>
      <SubContainer isFooter={isFooter}>
        {links?.instagram && <Instagram cursor={'pointer'} onClick={() => openLink(links?.instagram)} style={{marginRight: links?.facebook && links?.youtube ? '16px' : '0'}}/>}
        {links?.facebook && <FacebookOutlined cursor={'pointer'} onClick={() => openLink(links?.facebook)} style={{marginRight: links?.youtube ? '16px' : '0'}}/>}
        {links?.youtube && <YouTube cursor={'pointer'} onClick={() => openLink(links?.youtube)} style={{marginRight: '16px'}}/>}
      </SubContainer>
      <SubContainer>
        {phones?.join(' | ')}
      </SubContainer>
    </Container>
  );
};

export default HeaderTop;