export const getHTMLfromText = (text) => {
  let html = text.replaceAll('<b>', '<span style="font-weight: bold;margin: 0 6px">')
  html = html.replaceAll('</b>', '</span>')
  return html;
}

export const capitalizeWords = (text) => {
  text = text.charAt(0).toUpperCase() + text.slice(1);
  return text.replace(/(?<=\s)\w/g, (match) => match.toUpperCase());
}

export const urlToText = (text) => {
  return capitalizeWords(text?.replaceAll('-', ' '));
}

export const textToURL = (text) => {
  return text?.replaceAll(' ', '-').toLowerCase();
}