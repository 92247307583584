export type Color = 'primary' |  'neutral' |  'transparent' |  'success' |  'warning' |  'error' |  'info' |  'gradient';

export type ColorVariant = 'main' | 'a100' | 'a200' | 'a300' | 'a400' | 'a500' | 'a600' | 'a700' | 'a800' | 'a900' | 'white' |
  'neutral100' | 'neutral400' | 'scrim' | 'scrimDark' | 'tone2' | 'tone3';

export type ContrastColor = 'white' | 'black';

export const Colors: Record<Color, any> = {
  primary: {
    main: '#FFBD4A',
    a100: '#FFF8EC',
    a200: '#FEECCA',
    a300: '#F2CF8F',
    a400: '#F6C46B',
    a500: '#FFBD4A',
  },
  neutral: {
    main: '#A8B1BD',
    white: '#FFFFFF',
    a100: '#F8F9FB',
    a200: '#EEF1F6',
    a300: '#E4E8EE',
    a400: '#CDD3DD',
    a500: '#A8B1BD',
    a600: '#6A7381',
    a700: '#49505A',
    a800: '#37373C',
    a900: '#2C2B3A',
  },
  transparent: {
    main: 'transparent',
    neutral100: '#F8F9FB55',
    neutral400: '#CDD3DD55',
    scrim: '#00000025',
    scrimDark: '#00000055',
  },
  success: {
    main: '#078364',
    a100: '#DDFDED',
    a200: '#0AC295',
    a300: '#09A57F',
    a400: '#078364',
    a500: '#027357',
  },
  warning: {
    main: '#E07C02',
    a100: '#FEF7B9',
    a200: '#FFDA6C',
    a300: '#FFB400',
    a400: '#E07C02',
    a500: '#C33E01',
  },
  error: {
    main: '#C61A0B',
    a100: '#FCD2CF',
    a200: '#F45532',
    a300: '#DF320C',
    a400: '#C61A0B',
    a500: '#AE0A0A',
  },
  info: {
    main: '#5280F1',
    a100: '#F4F8FF',
    a200: '#C3D5FF',
    a300: '#89A7F1',
    a400: '#5280F1',
    a500: '#2C64EF',
  },
  gradient: {
    main: 'linear-gradient(180deg, #FFBD4A 0%, #FFF8EC 100%)',
    tone2: 'linear-gradient(180deg, #FFBD4A 0%, #FFF8EC 100%)',
    tone3: 'linear-gradient(180deg, #FFBD4A 0%, #F6C46B 50.11%, #FFF8EC 100%)',
  }
}

export const CONTRAST_COLORS_KEY: Record<ContrastColor, string> = {
  white: '#FFFFFF',
  black: '#121417',
}

export const CONTRAST_COLORS: Record<Color, any> = {
  primary: {
    main: CONTRAST_COLORS_KEY.white,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.black,
    a400: CONTRAST_COLORS_KEY.black,
    a500: CONTRAST_COLORS_KEY.black,
  },
  neutral: {
    main: CONTRAST_COLORS_KEY.black,
    white: CONTRAST_COLORS_KEY.black,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.black,
    a400: CONTRAST_COLORS_KEY.black,
    a500: CONTRAST_COLORS_KEY.black,
    a600: CONTRAST_COLORS_KEY.white,
    a700: CONTRAST_COLORS_KEY.white,
    a800: CONTRAST_COLORS_KEY.white,
    a900: CONTRAST_COLORS_KEY.white,
  },
  transparent: {
    main: CONTRAST_COLORS_KEY.black,
    neutral100: CONTRAST_COLORS_KEY.black,
    neutral400: CONTRAST_COLORS_KEY.black,
    scrim: CONTRAST_COLORS_KEY.black,
    scrimDark: CONTRAST_COLORS_KEY.white,
  },
  success: {
    main: CONTRAST_COLORS_KEY.white,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.black,
    a400: CONTRAST_COLORS_KEY.white,
    a500: CONTRAST_COLORS_KEY.white,
  },
  warning: {
    main: CONTRAST_COLORS_KEY.black,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.black,
    a400: CONTRAST_COLORS_KEY.black,
    a500: CONTRAST_COLORS_KEY.white,
  },
  error: {
    main: CONTRAST_COLORS_KEY.black,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.white,
    a400: CONTRAST_COLORS_KEY.white,
    a500: CONTRAST_COLORS_KEY.white,
  },
  info: {
    main: CONTRAST_COLORS_KEY.black,
    a100: CONTRAST_COLORS_KEY.black,
    a200: CONTRAST_COLORS_KEY.black,
    a300: CONTRAST_COLORS_KEY.white,
    a400: CONTRAST_COLORS_KEY.white,
    a500: CONTRAST_COLORS_KEY.white,
  },
  gradient: {
    main: CONTRAST_COLORS_KEY.black,
    tone2: CONTRAST_COLORS_KEY.black,
    tone3: CONTRAST_COLORS_KEY.black,
  }
}

export const getContrastColor = (color: Color | undefined, variant?: ColorVariant | undefined): string => {
  if (color) {
    if (variant) {
      return CONTRAST_COLORS[color][variant];
    }
    return CONTRAST_COLORS[color].main;
  }
  return '';
}

export const getColor = (color: Color | undefined, variant?: ColorVariant | undefined): string => {
  if (color) {
    if (variant) {
      return Colors[color][variant];
    }
    return Colors[color].main;
  }
  return '';
}

export const getCssColorProps = (color: Color | undefined, variant?: ColorVariant | undefined): string => {
  if (color) {
    if (variant) {
      return `background: ${Colors[color][variant]}; color: ${CONTRAST_COLORS[color][variant]};`;
    }
    return `background: ${Colors[color].main}; color: ${CONTRAST_COLORS[color].main};`;
  }
  return '';
}